html {
    scroll-behavior: smooth;
}
body {
    scroll-behavior: smooth;
    // background-color: #eee !important;
    .MuiPaper-root {
        background-image: none;
    }
    .custom-svg-icon {
        display: inline-block;
        flex-shrink: 0;
        width: 1em;
        height: 1em;

        font-size: 1.5rem;

        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        user-select: none;
        fill: currentColor;
        * {
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

            fill: currentColor !important;
        }
    }
}
.MuiButtonBase-root {
    .MuiBottomNavigationAction-label {
        white-space: nowrap;
    }
}

// List subheader sticky position
.MuiList-root {
    .MuiListSubheader-root {
        &.MuiListSubheader-sticky {
            top: 64px;
            @include breakpoint("smAndDown") {
                top: 56px;
            }
            &.no-sticky {
                position: relative;
                top: 0 !important;
            }
        }
    }
}

.MuiFormControl-root {
    // Disable browser styling on autocompleted inputs
    input.MuiInputBase-input {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        &:-webkit-autofill {
            transition: all 5000s ease-in-out 0s !important;
        }
    }
}

// Disable scroll propagation on modals
// .MuiModal-root.MuiDialog-root {
//     overflow: hidden;
//     .MuiBackdrop-root {
//         overflow: hidden;
//     }
//     .MuiDialog-container.MuiDialog-scrollPaper {
//         overflow: hidden;
//         > .MuiPaper-root.MuiDialog-paper {
//             overflow: hidden;
//         }
//     }
// }
