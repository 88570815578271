.video-js {
    .vjs-big-play-button {
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }
    .vjs-play-progress {
        &:before {
            top: -0.55em;
        }
    }
    .vjs-slider-horizontal {
        .vjs-volume-level {
            &:before {
                top: -0.55em;
            }
        }
    }
    .vjs-volume-panel.vjs-control {
        display: none;
    }
    .vjs-play-control.vjs-control.vjs-button {
        position: absolute;
        bottom: 6.2em;
        left: 50%;
        z-index: 1;

        transform: translate(-50%, 0) scale(2);
    }
    .vjs-control-bar.vjs-custom-control-bar {
        bottom: 3em;

        height: 9em;
        .MuiButtonBase-root {
            &.Mui-disabled {
                color: rgb(255, 255, 255, 0.5);
            }
        }
    }
}
