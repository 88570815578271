$breakpoints: (
    "smAndDown": (
        max-width: 600px,
    ),
    "smAndUp": (
        min-width: 600px,
    ),
    "mdAndDown": (
        max-width: 900px,
    ),
    "mdAndUp": (
        min-width: 900px,
    ),
) !default;

@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    } @else {
        // If used a wrong $breakpoint
        @content;
    }
}
