:root {
    /* https://dev.cmedrobotics.com/confluence/display/BG/Introducing+the+CMR+colours */
    --cmr-pink: #e6117b;
    --cmr-blue: #6bc2b8;
    --cmr-mid-blue: #02334e;
    --cmr-dark-blue: #002133;
    /* https://dev.cmedrobotics.com/confluence/display/BG/Links+and+buttons */
    --cmr-button-pink: #e4117a;
    --cmr-button-pink-hover: #be0f66;

    /* dark colors */
    --primary-dark-text: #66b2ff;
    --primary-light-text: #6bc2b8;
    --primary-dark-bg: #0a1929;
    --primary-light-bg: #f3f6f9;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    font-family: "Open Sans", "Segoe UI", sans-serif;
    line-height: 1.5;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
}

.App {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;

    color: white;
    font-size: 16px;

    background-color: var(--cmr-mid-blue);
}

.logo {
    /* https://dev.cmedrobotics.com/confluence/display/BG/Using+the+CMR+logo+in+a+header */
    width: 100px;
    margin: 10px 10px;

    font-weight: normal;
    vertical-align: middle;
}

.username {
    margin-left: auto;

    white-space: nowrap;
}

.avatar {
    height: 50px;
    padding: 0 5px;
}

.logout-button {
    min-width: 90px;

    margin: 0 15px;
    padding: 5px 16px;

    font-size: 0.9em;

    border-radius: 5px;
}

/* COMMON */

a {
    text-decoration: none;
}

.fade-enter {
    transform: translateY(20px);
    opacity: 0;
}
.fade-enter-active {
    transform: translateY(0);
    opacity: 1;
}
.fade-exit {
    transform: translateY(0);
    opacity: 1;
}
.fade-exit-active {
    transform: translateY(-20px);
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
    transition: opacity 400ms, transform 400ms;
}

.tabs-enter {
    transform: translateX(-100%);
    opacity: 0;
}
.tabs-enter-active {
    transform: translateX(0);
    opacity: 1;
}
.tabs-exit {
    transform: translateX(0);
    opacity: 1;
}
.tabs-exit-active {
    transform: translateX(100%);
    opacity: 0;
}
.tabs-enter-active,
.tabs-exit-active {
    transition: opacity 400ms, transform 400ms;
}
