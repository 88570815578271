html {
    --scrollbar-track-color: rgba(var(--primary-light-bg), 0);
    --scrollbar-thumb-color: var(--primary-light-text);

    &[data-mode="dark"] {
        --scrollbar-track-color: var(--primary-dark-bg);
        --scrollbar-thumb-color: var(--primary-dark-text);
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 0;

        border-radius: 0;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track-color);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-color);
        border-radius: 0;
    }
}

#root {
    display: flex;
    justify-content: center;
    
    > .MuiBox-root {
        width: calc(100vw - 240px);
    }

    @include breakpoint("mdAndDown") {
        justify-content: center;
        > .MuiBox-root {
            width: 100%;
        }
    }
}
