.qr-reader {
    & > section {
        & > div {
            border: 150px solid rgba(0, 0, 0, 0.3) !important;
            box-shadow: #ffffff 0px 0px 0px 3px inset !important;
            &::before {
                position: absolute;
                top: calc(100% + 20px);
                left: 50%;

                width: 85vw;
                max-width: 265px;

                color: #ffffff;
                font-size: 15px;
                text-align: center;

                transform: translate(-50%, 0);

                content: "Scan the QR code shown on the Versius screen";
            }
            @include breakpoint("mdAndDown") {
                border: 75px solid rgba(0, 0, 0, 0.3) !important;
                &::before {
                    top: calc(100% + 15px);
                }
            }
            @include breakpoint("smAndDown") {
                &::before {
                    top: calc(100% + 15px);

                    font-size: 13px;
                }
            }
        }
    }
}
